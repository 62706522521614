<template>
 <div class="bg-white shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
  <div class="max-w-7xl mx-auto 2xl:px-0 px-4">
   <form
    @submit.prevent="checkForm($t('elementUpdatedSuccessfully', { element: $t('contact') }))"
    method="POST"
   >
    <div class="space-y-10">
     <div class="pt-5 border-b border-gray-200 pb-12">
      <div class="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
       <div class="sm:col-span-3">
        <label for="company-name" class="block text-sm font-medium leading-6 text-gray-900">
         {{ $t("contactsForm.companyName") }}
        </label>
        <div class="mt-2">
         <input
          v-model="form.companyName"
          type="text"
          name="company-name"
          id="company-name"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
         />
        </div>
       </div>

       <div class="sm:col-span-3">
        <label for="id-code" class="flex text-sm font-medium leading-6 text-gray-900">
         {{ $t("contactsForm.identificationCode") }}
         <p class="ml-2 text-xs italic text-gray-700">({{ $t("idCodeFieldRequirement") }})</p>
        </label>
        <div class="mt-2">
         <input
          v-model="form.idCode"
          type="text"
          name="id-code"
          id="id-code"
          :required="!form.contactsNumber"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
         />
        </div>
       </div>

       <div class="sm:col-span-3">
        <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">
         {{ $t("contactsForm.firstName") }}
        </label>
        <div class="mt-2">
         <input
          v-model="form.fName"
          id="first-name"
          name="first-name"
          type="text"
          autocomplete="first-name"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
         />
        </div>
       </div>
       <div class="sm:col-span-3">
        <label for="last-name" class="block text-sm font-semibold leading-6 text-gray-900">
         {{ $t("contactsForm.lastName") }}*
        </label>
        <div class="mt-2">
         <input
          v-model="form.lName"
          type="text"
          name="last-name"
          id="last-name"
          autocomplete="given-name"
          required
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
         />
        </div>
       </div>

       <div class="col-span-full">
        <label for="contacts-number" class="block text-sm font-medium leading-6 text-gray-900">
         {{ $t("callingNumbers") }}
        </label>
        <div class="mt-2">
         <div v-if="this.errorContact.length" class="text-red-500 text-xs">
          <ul>
           <li v-for="error in errorContact" :key="error">
            {{ error }}
           </li>
          </ul>
         </div>
         <textarea
          v-model="form.contactsNumber"
          rows="2"
          name="contacts-number"
          id="contacts-number"
          :class="[
           errorContact.length
            ? 'ring-red-300 placeholder:text-red-400 focus:ring-2 focus:ring-inset focus:ring-red-600'
            : 'ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 text-gray-900',
           'block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset sm:text-sm sm:leading-6',
          ]"
         />
        </div>
       </div>
      </div>
     </div>

     <div class="border-b border-gray-900/10 pb-12">
      <fieldset>
       <div class="flex">
        <legend class="sr-only">Options</legend>
        <div class="relative flex items-center">
         <div class="flex items-center h-5">
          <input
           v-model="form.vip"
           value="1"
           id="comments"
           aria-describedby="comments-description"
           name="comments"
           type="checkbox"
           class="rounded focus:encom_border_input h-4 w-4 border-gray-300 rounded"
          />
         </div>
         <div class="ml-3 text-sm">
          <label for="comments" class="font-medium text-gray-700">
           {{ $t("contactsForm.vip") }}
          </label>
          <span id="comments-description" class="text-gray-500"
           ><span class="sr-only">VIP </span></span
          >
         </div>
        </div>
        <div class="relative flex items-center ml-20">
         <div class="flex items-center h-5">
          <input
           v-model="form.onCall"
           value="1"
           id="candidates"
           aria-describedby="candidates-description"
           name="candidates"
           type="checkbox"
           class="rounded focus:encom_border_input h-4 w-4 border-gray-300 rounded"
          />
         </div>
         <div class="ml-3 text-sm">
          <label for="candidates" class="font-medium text-gray-700">{{
           $t("contactsForm.onCall")
          }}</label>
          <span id="candidates-description" class="text-gray-500"
           ><span class="sr-only">Under on-call contract </span></span
          >
         </div>
        </div>
       </div>
      </fieldset>
     </div>
     <div class="border-b border-gray-200 pb-12">
      <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
       <div class="sm:col-span-4">
        <label for="dest-1" class="block text-sm font-semibold text-gray-700 sm:mt-px sm:pt-2">
         Destination 1*
        </label>
        <div class="mt-2 sm:col-span-2">
         <div class="relative">
          <input
           v-model="form.dest1"
           type="text"
           name="dest-1"
           id="dest-1"
           required
           :class="[
            errorDest1.length
             ? 'ring-red-300 placeholder:text-red-400 focus:ring-2 focus:ring-inset focus:ring-red-600'
             : 'ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 text-gray-900',
            'block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset sm:text-sm sm:leading-6',
           ]"
          />
          <div
           v-if="this.errorDest1.length"
           class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4"
          >
           <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
         </div>
         <div v-if="this.errorDest1.length">
          <p v-for="error in errorDest1" :key="error" class="ml-1 mt-1 text-sm text-red-600">
           {{ error }}
          </p>
         </div>
        </div>
       </div>
       <div class="sm:col-span-2 sm:col-start-1">
        <label for="dest-2" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
         Destination 2
        </label>
        <div class="mt-2 sm:col-span-2">
         <div class="relative">
          <input
           v-model="form.dest2"
           type="text"
           name="dest-2"
           id="dest-2"
           :class="[
            errorDest2.length
             ? 'ring-red-300 placeholder:text-red-400 focus:ring-2 focus:ring-inset focus:ring-red-600'
             : 'ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 text-gray-900',
            'block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset sm:text-sm sm:leading-6',
           ]"
          />
          <div
           v-if="this.errorDest2.length"
           class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4"
          >
           <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
         </div>
         <div v-if="this.errorDest2.length">
          <p v-for="error in errorDest2" :key="error" class="ml-1 mt-1 text-sm text-red-600">
           {{ error }}
          </p>
         </div>
        </div>
       </div>
       <div class="sm:col-span-2">
        <label for="dest-3" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
         Destination 3
        </label>
        <div class="mt-2 sm:col-span-2">
         <div class="relative">
          <input
           v-model="form.dest3"
           type="text"
           name="dest-3"
           id="dest-3"
           :class="[
            errorDest3.length
             ? 'ring-red-300 placeholder:text-red-400 focus:ring-2 focus:ring-inset focus:ring-red-600'
             : 'ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 text-gray-900',
            'block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset sm:text-sm sm:leading-6',
           ]"
          />
          <div
           v-if="this.errorDest3.length"
           class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4"
          >
           <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
         </div>
         <div v-if="this.errorDest3.length">
          <p v-for="error in errorDest3" :key="error" class="ml-1 mt-1 text-sm text-red-600">
           {{ error }}
          </p>
         </div>
        </div>
       </div>
       <div class="sm:col-span-2">
        <label for="dest-4" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
         Destination 4
        </label>
        <div class="mt-2 sm:col-span-2">
         <div class="relative">
          <input
           v-model="form.dest4"
           type="text"
           name="dest-4"
           id="dest-4"
           :class="[
            errorDest4.length
             ? 'ring-red-300 placeholder:text-red-400 focus:ring-2 focus:ring-inset focus:ring-red-600'
             : 'ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 text-gray-900',
            'block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset sm:text-sm sm:leading-6',
           ]"
          />
          <div
           v-if="this.errorDest4.length"
           class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4"
          >
           <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
         </div>
         <div v-if="this.errorDest4.length">
          <p v-for="error in errorDest4" :key="error" class="ml-1 mt-1 text-sm text-red-600">
           {{ error }}
          </p>
         </div>
        </div>
       </div>
      </div>
     </div>
    </div>

    <div class="mt-6 pb-12 flex items-center justify-end gap-x-6">
     <button
      type="button"
      @click="this.$router.push('/contacts-routes')"
      class="rounded-md bg-white px-3 py-2 border border-gray-300 shadow-sm text-sm font-semibold text-gray-900 hover:bg-gray-50"
     >
      {{ $t("ticket.cancel") }}
     </button>
     <button
      v-show="
       userPermissions.permissions.includes('smart-routings.*') ||
       userPermissions.permissions.includes('smart-routings.edit') ||
       userPermissions.permissions.includes('smart-routings.create')
      "
      type="submit"
      class="rounded-md body__table--header px-3 py-2 text-sm font-semibold text-white shadow-sm"
     >
      {{ $t("ticket.send") }}
     </button>
    </div>
   </form>
  </div>
 </div>
</template>

<script>
import axios from "axios";
import { ExclamationCircleIcon } from "@heroicons/vue/solid";

const userPermissions = JSON.parse(localStorage.getItem("userPermissions"));

export default {
 props: ["contact", "id", "account"],
 components: {
  ExclamationCircleIcon,
 },
 data() {
  return {
   showMessage: false,
   messageToShow: "",
   subMessageToShow: "",
   errorContact: [],
   errorDest1: [],
   errorDest2: [],
   errorDest3: [],
   errorDest4: [],
   form: {
    companyName: "",
    idCode: null,
    customerAccount: "",
    fName: "",
    lName: "",
    contactsNumber: null,
    vip: 0,
    onCall: 0,
    dest1: "",
    dest2: "",
    dest3: "",
    dest4: "",
   },
   userPermissions,
  };
 },
 methods: {
  checkForm(msg) {
   let contactsErrMsg = this.$t("contactsErrMsg");
   let destErrMsg = this.$t("destErrMsg");

   this.errorContact = [];
   this.errorDest1 = [];
   this.errorDest2 = [];
   this.errorDest3 = [];
   this.errorDest4 = [];

   if (
    this.validateContacts(this.form.contactsNumber) &&
    this.validateDest(this.form.dest1) &&
    this.validateDest(this.form.dest2) &&
    this.validateDest(this.form.dest3) &&
    this.validateDest(this.form.dest4)
   ) {
    this.onSubmit(msg);
   } else if (!this.validateContacts(this.form.contactsNumber)) {
    this.errorContact.push(contactsErrMsg);
   } else if (!this.validateDest(this.form.dest1)) {
    this.errorDest1.push(contactsErrMsg);
   } else if (!this.validateDest(this.form.dest2)) {
    this.errorDest2.push(contactsErrMsg);
   } else if (!this.validateDest(this.form.dest3)) {
    this.errorDest3.push(contactsErrMsg);
   } else if (!this.validateDest(this.form.dest4)) {
    this.errorDest4.push(contactsErrMsg);
   }
  },
  async onSubmit(msg) {
   axios
    .put(
     `${this.$cookie.getCookie("API")}/api/v1/contacts/${this.id}`,
     {
      customerAccount: this.form.customerAccount,
      companyName: this.form.companyName,
      firstName: this.form.fName,
      lastName: this.form.lName,
      vip: this.form.vip,
      onCallDuty: this.form.onCall,
      code: this.form.idCode,
      contacts: this.form.contactsNumber,
      destination1: this.form.dest1,
      destination2: this.form.dest2,
      destination3: this.form.dest3,
      destination4: this.form.dest4,
     },
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     }
    )
    .then((res) => {
     if (res) {
      this.$emit("activeBanner");
      this.$emit("success", true);
      this.$emit("successMsg", msg);
      this.$emit("closeForm");
     }
    })
    .catch((err) => {
     this.errorHandling(err);
     this.$emit("activeBanner");
     this.$emit("success", false);
     this.$emit("successMsg", this.errorDataResponse);
    });
  },
  validateContacts(contacts) {
   let validRegEx = /^[0-9,]*$/;
   return contacts.match(validRegEx);
  },
  validateDest(dest) {
   let validRegEx = /^[0-9]*$/;
   if (dest) {
    if (dest === "") {
     return "ok";
    }
    return dest.match(validRegEx);
   } else {
    return "ok";
   }
  },
  sendMessage(msg, subMsg) {
   this.showMessage = true;
   this.messageToShow = msg;
   this.subMessageToShow = subMsg;
  },
 },
 mounted() {
  this.form.companyName = this.contact.companyName;
  this.form.idCode = this.contact.code;
  this.form.customerAccount = this.contact.customerAccount;
  this.form.fName = this.contact.firstName;
  this.form.lName = this.contact.lastName;
  this.form.contactsNumber = this.contact.contacts;
  this.form.vip = this.contact.vip ? true : false;
  this.form.onCall = this.contact.onCallDuty ? true : false;
  this.form.dest1 = this.contact.destination1;
  this.form.dest2 = this.contact.destination2;
  this.form.dest3 = this.contact.destination3;
  this.form.dest4 = this.contact.destination4;
 },
};
</script>

<style></style>
