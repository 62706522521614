<template>
 <div class="p-10 mb-5">
  <UpdateContactForm
   :key="this.contact"
   :account="this.account"
   :id="this.id"
   :contact="this.contact"
   :companyName="contact.companyName"
   :idCode="contact.code"
   @activeBanner="$emit('activeBanner')"
   @successMsg="$emit('successMsg', $event)"
   @success="$emit('success', $event)"
   @closeForm="this.$router.push('/contacts-routes')"
  />
 </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { ChevronDownIcon } from "@heroicons/vue/solid";

import UpdateContactForm from "../components/UpdateContactForm.vue";

export default {
 name: "ContactDetail",
 props: ["account", "id"],
 components: {
  ChevronDownIcon,
  UpdateContactForm,
 },
 data() {
  return {
   contact: {},
   showMessage: false,
   messageToShow: "",
   subMessageToShow: "",
   openForm: true,
   successMsg: "",
   activeBanner: false,
   isSuccess: true,
   key: false,
  };
 },
 methods: {
  async getContact() {
   this.$emit("setLoading", true);
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie("API")}/api/v1/contacts/${this.id}?customerAccount=${this.account}`
    );
    this.contact = res.data;
   } catch (error) {
    this.errorHandling(error);
   } finally {
    this.$emit("setLoading", false);
   }
  },
  contactsArray(contacts) {
   let array = String(contacts).split(",");
   return array;
  },

  deleteContact(msg) {
   axios
    .delete(
     `${this.$cookie.getCookie("API")}/api/v1/contacts/${this.id}?customerAccount=${this.account}`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     }
    )
    .then((res) => {
     if (res) {
      this.sendMessage(msg);
     }
    })
    .catch((err) => console.error(err));
  },

  sendMessage(msg, subMsg) {
   this.showMessage = true;
   this.messageToShow = msg;
   this.subMessageToShow = subMsg;
  },
 },
 mounted() {
  this.getContact();
 },
 updated() {
  if (!this.contact.customerAccount) {
   this.$router.push("/contacts-routes");
  }
 },
};
</script>

<style>
.card_info {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 border-radius: 10px;
}
.card_info_data {
 box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
 max-width: fit-content;
 border-radius: 10px;
 margin: 90px 20px 0px 20px;
 overflow-x: auto;
}
.user_card_padding {
 padding: 30px;
}
.form_update_contact_padding {
 padding: 5px 40px 10px 40px;
}
</style>
